$font-text: 'Open Sans', sans-serif;
$font-header-h1: 'Open Sans', sans-serif;


h1 {
  font-family: $font-header-h1;
  font-weight: 600;
}

html {
  font-size: 14px;
  font-family: $font-text;
  scroll-behavior: smooth;
  overflow-y: scroll;
  background-color: #f3f3f3;
  scrollbar-width: thin;

  @media (min-width: 1250px) {
    font-size: 18px;
  }
}



body {
  width:100%;
  overflow:hidden;
}

/* <Layout> */

.mobile-menu {
  visibility:hidden;
  height:0px;
  overflow:hidden;
  transition:0.5s;
  -webkit-transition:0.5s;
  transition-timing-function: ease-out;
}
.mobile-menu.visible {
  visibility:visible;
  height:263px;
}

/* </Layout> */

.full-menu__container {
  z-index: 2;
}
.home {
  &-content {
    margin-top: 85px;
    background-image: url("../../public/img/home-bg.jpg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    height: calc(100vh - 70px);
    @media (min-width: 1250px) {
      margin-top: 70px;
      width: calc(100vw);
    }
    @media (min-aspect-ratio: 2/1) {
      //background-size: contain;
      height: calc(100vh + 280px);
    }

    //@media (max-width: 1250px) {
    //  margin-top: 70px;
    //}
  }
}

.about-us-map {
  background-image: url("../../public/img/map-lg.svg");
  background-repeat: no-repeat;
  background-position: center;
}

.about-us-map.sm {
  background-image: url("../../public/img/map-sm.svg") !important;
}

.parent-grid {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: repeat(1, 1fr);
  grid-column-gap: 5px;
  grid-row-gap: 5px;
}

.div1 { grid-area: 1 / 1 / 2 / 3; }
.div2 { grid-area: 1 / 3 / 2 / 4; }
.div3 { grid-area: 1 / 4 / 2 / 6; }



.pic {
  &-universities {
    background: white;
    background-image: url("../../public/img/universities.svg");
    background-repeat: no-repeat;
    background-position: center;
  }

  &-email {
    background-image: url("../../public/img/ic_email.svg");
    background-repeat: no-repeat;
    background-position: left;
  }
  &-phone{
    background-image: url("../../public/img/ic_phone.svg");
    background-repeat: no-repeat;
    background-position: left;
  }
}


.advantage, .key-directions-block {
  border: 1px solid #FAA71B;
  padding: 1rem 2rem 1rem 2rem;
  border-radius: 1rem;
  * {
    font-weight: 600;
  }
}


.key-directions {
  &-number {
    color: #FAA71B;
    font-size: 3rem;
    margin-left: 2rem;
    margin-right: 2rem;
  }
}

.sub-header {
  font-size: 1rem;
  font-weight: 600;
  @media (min-width: 1250px) {
    font-size: 26px;
  }
}
