@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  a {
    @apply text-rorange;
    &:hover {
      text-decoration: underline;
    }
  }
  div {
    @apply text-base;
  }
  h1 {
    @apply text-xl;
    @media (min-width: 1250px) {
      line-height: 2.6rem;
    }
  }
  h2 {
    @apply text-rorange;
    @apply text-xl;
    @apply mb-12;

    font-weight: 600;
  }
  h3 {
    @apply text-rorange;
    @apply mb-14;
    @apply text-lg;

    @media (min-width: 1250px) {
      font-size: 35px;
    }
  }
  h4 {
    @apply mb-4;
    font-size: 1.25rem;

    @media (min-width: 1250px) {
      font-size: 1.5rem;
    }
  }
}
